<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ name: 'campaignIndex' }">{{ $t('seller.campaign.title') }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ pageInfo.name }}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="page-head">
				<div class="title">{{ pageInfo.name }}<el-tag type="info">{{ pageInfo.label_name }}</el-tag>
				</div>
				<ul class="text">
					<li>
						<span>{{ $t('seller.campaign.shopSignUpTime') + '：' }}{{ pageInfo.s_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ pageInfo.s_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</span>
						<el-tag type="info" size="mini">{{ onActivityStatus(pageInfo.s_enroll_start_time, pageInfo.s_enroll_end_time) }}</el-tag>
					</li>
					<li>
						<span>{{ $t('seller.campaign.itemSignUpTime') + '：' }}{{ pageInfo.g_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ pageInfo.g_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</span>
						<el-tag type="info" size="mini">{{ onActivityStatus(pageInfo.g_enroll_start_time, pageInfo.g_enroll_end_time) }}</el-tag>
					</li>
					<li>
						<span>{{ $t('seller.campaign.activityTime') + '：' + $t('seller.campaign.warmUpText1') }}{{ onActivityDays(pageInfo.warm_up_start_time,pageInfo.warm_up_end_time) }}{{ $t('seller.campaign.warmUpText2') }}{{ onActivityDays(pageInfo.sales_start_time,pageInfo.sales_end_time) }}{{ $t('seller.campaign.warmUpText3') }}</span>
					</li>
				</ul>
			</div>
			<div class="page-step">
				<el-steps :active="onActivityStep()" align-center>
					<el-step :title="$t('seller.campaign.shopSignUp')">
						<template slot="description">{{ pageInfo.s_enroll_start_time | parseTime() }}</template>
					</el-step>
					<el-step :title="$t('seller.campaign.itemSignUp')">
						<template slot="description">{{ pageInfo.g_enroll_end_time | parseTime() }} {{ $t('seller.campaign.end') }}</template>
					</el-step>
					<el-step :title="$t('seller.campaign.activityWarmUp')">
						<template slot="description">{{ pageInfo.warm_up_start_time | parseTime() }}</template>
					</el-step>
					<el-step :title="$t('seller.campaign.activitySales')">
						<template slot="description">{{ pageInfo.sales_start_time | parseTime() }}</template>
					</el-step>
					<el-step :title="$t('seller.campaign.activityEnd')">
						<template slot="description">{{ pageInfo.sales_end_time | parseTime() }}</template>
					</el-step>
				</el-steps>
			</div>
			<div class="page-filters">
				<el-button type="info" size="medium" class="filter-item" @click="handleSelectGoods">{{ $t('seller.actions.selectGoods') }}</el-button>
			</div>
			<el-dialog :title="$t('seller.dialogTitle.optionalItems')" :visible.sync="dialogVisibleGoods" width="900px" custom-class="seller-dialog">
				<div class="dialog-page">
					<div class="page-top">{{ $t('seller.campaign.dialogText1') }}{{ dialogCheck.length }}{{ $t('seller.unit.pcs') }}</div>
					<div class="page-tips">{{ $t('seller.campaign.dialogTips1') }}{{ dialogSatisfy }}{{ $t('seller.campaign.dialogTips2') }}{{ dialogTotal - dialogSatisfy }}{{ $t('seller.campaign.dialogTips3') }}</div>
					<el-form size="mini" :inline="true">
						<el-form-item>
							<el-input v-model="dialogQuery.goods_ids" :placeholder="$t('seller.screen.goodsId')"></el-input>
						</el-form-item>
						<el-form-item>
							<el-input v-model="dialogQuery.goods_name" :placeholder="$t('seller.screen.goodsName')"></el-input>
						</el-form-item>
						<el-form-item>
							<el-select v-model="dialogQuery.goods_status" :placeholder="$t('seller.screen.goodsStatus')">
								<el-option v-for="item in $t('seller.options.goodsStatus')" :key="item.label" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-cascader v-model="dialogQuery.goods_cate_id" :options="cateOptions"
								:props="{checkStrictly: true,value:'cate_id',label:'cate_name',children:'children'}"
								:placeholder="$t('seller.tableHead.category')" clearable>
							</el-cascader>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="handleDialogSearch">{{ $t('seller.actions.search') }}</el-button>
						</el-form-item>
					</el-form>
					<el-table v-loading="dialogLoading" ref="dialogTable" :data="dialogGoods" :row-key="getRowKey" @select="handleDialogSelection" @select-all="handleDialogSelection" @selection-change="handleDialogSelectionChange">
						<el-table-column type="selection"
							:selectable="(row,index) => { return row.is_satisfy > 0 ? true : false }"
							:reserve-selection="true">
						</el-table-column>
						<el-table-column :label="$t('seller.tableHead.goods')" min-width="200px">
							<template slot-scope="scope">
								<div class="goods-item">
									<a class="pic" :href="$itemBasePath+'/item/'+scope.row.goods_id" target="_blank"><img :src="scope.row.default_image" @error="goodsLoadError" alt=""></a>
									<div class="item-main">
										<a class="name" :href="$itemBasePath+'/item/'+scope.row.goods_id" target="_blank">{{ scope.row.goods_name }}</a>
										<div class="text">{{ $t('seller.screen.goodsId') + ':'}}{{ scope.row.goods_id }}</div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column :label="$t('seller.screen.goodsStatus')">
							<template slot-scope="scope">{{ scope.row.is_violation === 1 ? 2 : scope.row.if_show | typeFilter($t('seller.filters.goodsType'))}}</template>
						</el-table-column>
						<el-table-column :label="$t('seller.formItem.goodsPrice')">
							<template slot-scope="scope">
								￥{{ scope.row.price }}
							</template>
						</el-table-column>
						<el-table-column :label="$t('seller.tableHead.onlineStock')" prop="stock"></el-table-column>
						<el-table-column :label="$t('seller.tableHead.category')" prop="cate_name"></el-table-column>
						<el-table-column :label="$t('seller.tableHead.accordWith')" width="120px">
							<template slot-scope="scope">
								{{ scope.row.is_satisfy > 0 ? 1 : 0 | typeFilter($t('seller.filters.accordWith')) }}
							</template>
						</el-table-column>
					</el-table>
					<pagination v-show="dialogTotal > 0" :total="dialogTotal" :page.sync="dialogCurr" :limit.sync="dialogSize"
						@pagination="getDialogGoods"></pagination>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button size="small" @click="dialogVisibleGoods = false">{{ $t('seller.actions.cancel') }}</el-button>
					<el-button type="primary" size="small" v-loading="dialogLoaidng" @click="handleDialogSubmit">{{ $t('seller.actions.submit') }}</el-button>
				</div>
			</el-dialog>
			<div class="page-filters">
				<el-form size="mini" :inline="true">
					<el-form-item :label="$t('seller.tableHead.goods')+':'">
						<el-input v-model="pageQuery.goods_ids" :placeholder="$t('seller.screen.goodsId')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="pageQuery.goods_name" :placeholder="$t('seller.screen.goodsName')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-select v-model="pageQuery.goods_status" :placeholder="$t('seller.screen.goodsStatus')">
							<el-option v-for="item in $t('seller.options.goodsStatus')" :key="item.label" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="pageQuery.enroll_status" :placeholder="$t('seller.screen.goodsSignUpStatus')">
							<el-option v-for="item in $t('seller.options.campaignStatus')" :key="item.label" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="pageQuery.per_status" :placeholder="$t('seller.screen.perfectStatus')">
							<el-option v-for="item in $t('seller.options.perfectStatus')" :key="item.label" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch">{{ $t('seller.actions.search') }}</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table v-loading="listLoading" :data="pageList" class="seller-table">
				<el-table-column :label="$t('seller.tableHead.goods')" min-width="200px">
					<template slot-scope="scope">
						<div class="goods-item">
							<a class="pic" :href="$itemBasePath+'/item/'+scope.row.goods_id" target="_blank"><img :src="scope.row.default_image" @error="goodsLoadError" alt=""></a>
							<div class="item-main">
								<a class="name" :href="$itemBasePath+'/item/'+scope.row.goods_id" target="_blank">{{ scope.row.goods_name }}</a>
								<div class="text">{{ $t('seller.screen.goodsId') + ':'}}{{ scope.row.goods_id }}</div>
								<div class="text">{{ $t('seller.campaign.marketId') + ':'}}{{ scope.row.activity_goods_id }}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.screen.goodsStatus')">
					<template slot-scope="scope">{{ scope.row.is_violation === 1 ? 2 : scope.row.if_show | typeFilter($t('seller.filters.goodsType'))}}</template>
				</el-table-column>
				<el-table-column :label="$t('seller.formItem.goodsPrice')" prop="price"></el-table-column>
				<el-table-column :label="$t('seller.tableHead.campaignPrice')" prop="activity_price">
					<template slot-scope="scope">
						<template v-if="parseFloat(scope.row.activity_price) > 0">
							{{ scope.row.activity_price | priceFilter() }}
						</template>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.stock')" prop="activity_stock">
					<template slot-scope="scope">
						{{ parseInt(scope.row.activity_stock) > 0 ? scope.row.activity_stock : $t('seller.campaign.allStock') }}
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.campaign.signUpTime')">
					<template slot-scope="scope">
						{{ scope.row.enroll_time | parseTime() }}
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.itemSignUpStatus')">
					<template slot-scope="scope">
						<span :class="{red: scope.row.enroll_status === 0}">{{ scope.row.enroll_status | typeFilter($t('seller.filters.itemSignUpStatus')) }}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.handle')">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" plain @click="handleEditShow(scope)">{{ scope.row.enroll_status > 0 ? $t('seller.actions.editGoods'):$t('seller.actions.perfectGoods')}}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
				@pagination="getEntryGoods"></pagination>
			<el-dialog :title="$t('seller.actions.editGoods')+'-'+postForm.goods_name" :visible.sync="dialogVisibleDetails" width="900px" custom-class="seller-dialog">
				<div class="dialog-form">
					<el-form ref="postForm" :model="postForm" :rules="postRules" label-width="120px" size="small">
						<el-form-item :label="$t('seller.screen.goodsTitle')"><a :href="$itemBasePath+'/item/'+postForm.goods_id" class="link-text" target="_blank">{{ postForm.goods_name }}</a></el-form-item>
						<el-form-item :label="$t('seller.screen.goodsId')">{{ postForm.goods_id }}</el-form-item>
						<el-form-item :label="$t('seller.screen.signUpType')">
							<el-radio v-model="postForm.enroll_type" :label="0">{{ $t('seller.campaign.signUpTips') }}</el-radio>
						</el-form-item>
						<el-form-item :label="$t('seller.screen.buyItNow')">{{ postForm.price }}{{ $t('seller.unit.yuan') }}</el-form-item>
						<el-form-item :label="$t('seller.screen.campaignGoodsPrice')" prop="activity_price">
							<el-input v-model="postForm.activity_price" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 120px">
								<template slot="append">{{ $t('seller.unit.yuan') }}</template>
							</el-input>
						</el-form-item>
						<el-form-item :label="$t('seller.screen.stockType')">
							<el-radio v-model="postForm.stock_type" :label="0">{{ $t('seller.campaign.stockTips') }}</el-radio>
						</el-form-item>
						<el-form-item :label="$t('seller.screen.signUpStock')" prop="activity_stock">
							<el-input v-model="postForm.activity_stock" oninput="value=value.replace(/[^\d]/g,'')" style="width: 120px">
								<template slot="append">{{ $t('seller.unit.piece') }}</template>
							</el-input>
							<span class="tips">{{ $t('seller.campaign.stockCurr') + '：' }}{{postForm.stock}}{{ $t('seller.unit.piece') }}</span>
						</el-form-item>
						<el-form-item :label="$t('seller.screen.limitBuy')" prop="limit_quantity">
							<el-input v-model="postForm.limit_quantity" oninput="if(value<1)value=1;if(value>9999)value=9999;value=value.replace(/[^\d]/g,'')" style="width: 120px"></el-input>
							<span>{{ $t('seller.unit.piece') }}</span>
							<span class="tips">{{ $t('seller.campaign.limitTips1') }}</span> 
							<div class="tips">{{ $t('seller.campaign.limitTips2') }}</div>
						</el-form-item>
						<el-form-item>
							<el-button size="small" @click="dialogVisibleDetails = false">{{ $t('seller.actions.cancel') }}</el-button>
							<el-button type="primary" size="small" v-loading="dialogLoaidng" @click="handleSubmit">{{ $t('seller.actions.submit') }}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-dialog>
		</el-card>
	</el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchCampaignInfo, fetchCampaignGoods, fetchEntryGoods, signUpGoods, uploadCampaignGoods } from '@/api/seller/market'
import { categoryShop } from '@/api/seller/category'
const defaultQuery = {
	goods_ids: '',
	goods_name: '',
	goods_status: '',
	goods_cate_id: ''
}
export default {
	components: { Pagination },
	data() {
		return {
			pageInfo: {},
			activeName: 'desc',
			submitLoading: false,
			dialogVisibleGoods: false,
			cateOptions: [],
			dialogQuery: Object.assign({},defaultQuery),
			dialogLoading: false,
			dialogGoods: [],
			dialogCurr: 1,
			dialogSize: 10,
			dialogTotal: 0,
			dialogSatisfy: 0,
			dialogCheck: [],
			dialogLoaidng: false,
			multipleSelection: [],
			pageQuery: {
				goods_ids: '',
				goods_name: '',
				goods_status: '',
				enroll_status: '',
			},
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0,
			dialogVisibleDetails: false,
			postForm: {
				activity_price: '',
				activity_stock: '',
				limit_quantity: 1
			},
			postRules: {
				activity_price: [
					{ required: true, message: this.$t('seller.validator.required'), trigger: 'blur' }
				],
				activity_stock: [
					{ required: true, message: this.$t('seller.validator.required'), trigger: 'blur' }
				],
				limit_quantity: [
					{ required: true, message: this.$t('seller.validator.required'), trigger: 'blur' }
				]
			}
		}
	},
	created() {
		this.getData()
		this.getEntryGoods()
	},
	methods: {
		async getData() {
			const category = await categoryShop({ is_platform: 1 })
			this.cateOptions = category.data.list
			const { data } = await fetchCampaignInfo({ activity_id: this.$route.params.id })
			this.pageInfo = Object.assign({}, data)
			this.dialogCheck = Object.assign([], data.goods_ids)
		},
		goodsLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		getDialogGoods() {
			this.dialogLoading = true
			const params = {
				...this.dialogQuery,
				activity_id: this.$route.params.id,
				page_index: (this.dialogCurr - 1) * this.dialogSize,
				page_size: this.dialogSize
			}
			fetchCampaignGoods(params).then(response => {
				const { data } = response
				this.dialogGoods = data.list
				this.dialogTotal = parseInt(data.count)
				this.dialogSatisfy = parseInt(data.satisfy_count)
				if (this.$refs.dialogTable && this.dialogCheck.length > 0) {
					for (let index = 0; index < this.dialogGoods.length; index++) {
						const element = this.dialogGoods[index]
						if (this.dialogCheck.includes(element.goods_id) && this.multipleSelection.indexOf(JSON.stringify(element)) < 0) {
							this.$refs.dialogTable.toggleRowSelection(element)
						}
					}
				}
				this.dialogLoading = false
			})
		},
		handleSelectGoods() {
			this.dialogVisibleGoods = true
			this.dialogQuery = Object.assign({},defaultQuery)
			this.dialogCurr = 1
			this.$nextTick(() => {
				this.getDialogGoods()
			})
		},
		handleDialogSearch() {
			this.dialogCurr = 1
			this.getDialogGoods()
		},
		handleDialogSelection(val,row) {
			var check = []
			val.forEach(item => {
				check.push(item.goods_id);
			})
			this.dialogGoods.forEach(item => {
				if(this.dialogCheck.includes(item.goods_id) && !check.includes(item.goods_id)) {
					this.dialogCheck.splice(this.dialogCheck.indexOf(item.goods_id), 1)
				}
				if(!this.dialogCheck.includes(item.goods_id) && check.includes(item.goods_id)) {
					this.dialogCheck.push(item.goods_id)
				}
			})
		},
		handleDialogSelectionChange(val) {
			var checkItems = []
			val.forEach(item => {
				checkItems.push(JSON.stringify(item))
			})
			this.multipleSelection = checkItems
		},
		handleDialogSubmit() {
			if (!this.dialogCheck.length > 0) {
				this.$message.error(this.$t('seller.errorMsg.selectGoods'))
				return false
			}
			this.dialogLoaidng = true
			const params = {
				activity_id: this.pageInfo.activity_id,
				goods_ids: this.dialogCheck.join(',')
			}
			signUpGoods(params).then(() => {
				this.$refs.dialogTable.clearSelection()
				this.dialogLoaidng = false
				this.dialogVisibleGoods = false
				this.$message.success(this.$t('seller.successMsg.add'))
				this.getEntryGoods()
			}).catch(() => {
				this.dialogLoaidng = false
			})
		},
		getEntryGoods() {
			this.listLoading = true
			const params = {
				...this.pageQuery,
				activity_id: this.$route.params.id,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchEntryGoods(params).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleSearch() {
			this.dialogCurr = 1
			this.getEntryGoods()
		},
		onActivityStep() {
			var step = 1
			if (this.pageInfo) {
				var currTime = parseInt(Date.now() / 1000)
				if (this.pageInfo.g_enroll_start_time < currTime && this.pageInfo.g_enroll_end_time > currTime) {
					step = 1
				}
				if (this.pageInfo.warm_up_start_time < currTime) {
					step = 2
				}
				if (this.pageInfo.sales_start_time < currTime) {
					step = 3
				}
				if (this.pageInfo.sales_end_time < currTime) {
					step = 4
				}
			}
			return step
		},
		onActivityStatus(startTime, endTime) {
			var currTime = parseInt(Date.now() / 1000)
			if (currTime < startTime) {
				return this.$t('seller.campaign.notStarted')
			} else if (currTime > startTime && currTime < endTime) {
				return this.$t('seller.campaign.inProgress')
			} else if (currTime > endTime) {
				return this.$t('seller.campaign.expired')
			}
		},
		onActivityDays(startTime, endTime) {
			const diff = endTime - startTime
			return Math.ceil(diff / 86400)
		},
		getRowKey(row) {
			return row.goods_id
		},
		handleEditShow({ row }) {
			this.postForm = Object.assign({},row)
			if(!this.postForm.activity_stock > 0) {
				this.postForm.activity_stock = ''
			}
			if(!this.postForm.activity_price > 0) {
				this.postForm.activity_price = ''
			}
			this.dialogVisibleDetails = true
		},
		handleSubmit() {
			if(this.postForm.activity_stock > this.postForm.stock) {
				this.$message.error(this.$t('seller.errorMsg.activityStock'))
				return false
			}
			this.$refs.postForm.validate(valid => {
				if(valid) {
					this.dialogLoaidng = true
					const params = {
						...this.postForm,
						activity_id: this.pageInfo.activity_id
					}
					uploadCampaignGoods(params).then(()=> {
						this.dialogLoaidng = false
						this.dialogVisibleDetails = false
						this.getEntryGoods()
					}).catch(() => {
						this.dialogLoaidng = false
					})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.page-head {
		padding: 18px;
		border-radius: 4px;
		background-color: rgb(249, 249, 249);

		.title {
			color: #111;
			font-size: 24px;

			.el-tag {
				color: #111111;
				margin-left: 8px;
				border-color: transparent;
				background-color: rgba(0, 0, 0, 0.06);
			}
		}

		.text {
			margin-top: 9px;

			li {
				font-size: 12px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 12px;

				span {
					margin-right: 4px;
				}

				.el-tag {
					color: rgb(17, 17, 17);
					border-color: transparent;
					background-color: rgba(0, 0, 0, 0.06);
				}
			}
		}
	}

	.page-step {
		margin-top: 18px;
	}

	.page-filters {
		margin-top: 18px;
	}
	.page-bottom {
		padding: 15px 0;
		text-align: center;
	}
	.red {
		color: #f40;
	}
}

.dialog-page {
	.page-top {
		padding: 10px 20px;
		border-radius: 4px;
		background-color: #f9f9f9;
		margin-bottom: 10px;
	}
	.page-tips {
		color: #888;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 10px;
	}

	.page-bottom {
		margin-top: 10px;
		text-align: center;
	}
}

.goods-item {
	display: flex;
	font-size: 12px;
	line-height: 1.5;

	.pic {
		flex-shrink: 0;
		width: 60px;
		height: 60px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.item-main {
		flex: 1;
		width: 0;
		margin-left: 10px;

		.name {
			color: $--seller-primary;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			margin-bottom: 4px;
		}

		.text {
			color: #999999;
		}
	}
}
.link-text {
	color: $--seller-primary;
}
.dialog-form {
	::v-deep .el-input {
		.el-input-group__append {
			padding: 0 10px;
		}
	}
	span {
		margin-left: 10px;
	}
	.tips {
		font-size: 12px;
		color: #999999;
	}
	::v-deep .el-radio {
		.el-radio__input {
			&.is-checked + .el-radio__label {
				color: #606266;
			}
		}
	}
}
</style>